@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;1,300;1,400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap') */


body {
  font-family: 'Prompt', sans-serif;
  font-family: "DM Sans", sans-serif;
  /* font-family: 'Kanit', sans-serif; */
  /* padding: 0 !important;  */
  /* margin: 0 !important;  */
  /* overflow-x: hidden; */
}

option {
  color: black;
}

.firebase-emulator-warning{
    display: none;
} 

.react-datepicker {
  display: inline-flex
}