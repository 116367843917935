@media screen and (max-width: 767px) {
  .products-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .product {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }

  .item {
    position: relative;
    margin-top: 50px;
    width: 70px;
    height: 100px;
    border: 1px solid gray;
    padding: 10px;
    display: block;
    margin-bottom: 10px;
    border-radius: 3px;
  }

  .cart-container {
    position: relative;
  }

  .cart {
    font-size: 1.5em;
    color: #fff;
  }

  .cart-count {
    position: absolute;
    top: -6px;
    right: -6px;
    line-height: 20px;
    height: 20px;
    width: 20px;
    font-size: 12px;
    font-weight: 600;
    background: none;
    color: white;
    border-radius: 20px;
  }

  .cart-item {
    position: absolute;
    height: 40px;
    width: 40px;
    top: -0px;
    right: -0px;
    /* z-index: 999; */
  }
  .cart-item::before {
    /* content: ' '; */
    display: block;
    line-height: 40px;
    height: 40px;
    width: 40px;
    font-size: 12px;
    font-weight: 600;
    background: #2bd156;
    color: white;
    border-radius: 20px;
    text-align: center;
    /* z-index: 999; */
  }
  .add-to-cart {
    border: none;
    padding: 15px 0px;
    border-radius: 3px;
    background: orange;
  }

  .topbar {
    width: 100%;
    background: lightslategray;
    padding: 10px;
    color: #fff;
    font-size: 2em;
  }

  .send-to-cart.cart-item {
    display: block;
    z-index: 7;

    animation: yAxis 2.5s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1);
    /* animation: xAxis 2.5s forwards cubic-bezier(1, 0.44, 0.84, 0.165); */
  }

  .send-to-cart.cart-item::before {
    animation: yAxis 2.5s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 1;
    z-index: 7;
  }

  .shake {
    animation: shakeCart 0.4s ease-in-out forwards;
    z-index: 7;
  }

  @keyframes xAxis {
    100% {
      transform: translateX(calc(45vw - 200px));
    }
  }

  @keyframes yAxis {
    100% {
      transform: translateY(90vh);
      opacity: 0;
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  @keyframes shakeCart {
    25% {
      transform: translateX(6px);
    }
    50% {
      transform: translateX(4px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
