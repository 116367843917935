.btn {
  padding: 17px 30px;
  border-radius: 50px;
  border: 0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.3s ease;
}

.btn:hover {
  letter-spacing: 3px;
  background-color: #60101C;
  color: hsl(0, 0%, 100%);
  box-shadow: #60101C 0px 7px 29px 0px;
}

.btn:active {
  letter-spacing: 3px;
  background-color: #60101C;
  color: hsl(0, 0%, 100%);
  box-shadow: #60101C 0px 0px 0px 0px;
  transform: translateY(1px);
  transition: 10ms;
}
